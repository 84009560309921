<template>
  <!-- 工人维度--基本信息 -->
  <div id="AlarmEventID">
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>

    <div class="table-button">
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('WorkerInfo-/api/worker/eventexport') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="WorkerInfo"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <!--:scroll="{ x: 2000 }"     :scroll="{ x: 1700, y: 'calc(853px - 105px )' }"-->
    <s-table
      ref="table"
      size="small"
      rowKey="serial"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1000, y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      @change="changeTable"
    >
      <!-- 照片 -->
      <span slot="e_img" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record)">
          <!-- <div class="actions">
            <div class="icon_out">
              <img
                :src="text"
                width="100%"
                height="100%"
                class="imgname"
                href="javascript:;"
              />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div> -->
          <!-- <div>
            {{ record.d_imei }}
          </div> -->
        </span>
      </span>
      <span slot="e_time" slot-scope="text">
        {{ $Format(text) }}
      </span>
    </s-table>
    <!-- <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :loading="loading"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal> -->

    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">
        报警照片（{{ ImgName }}）报警日期：<span style="color: red">{{
          Imgdate
        }}</span>
      </div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" href="javascript:;" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetAlarmEvent } from "@/api/image";
import { ExportEvent } from "@/api/export";
import { urlConfig } from "@/config/config";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetCompanies } from "@/api/device";
import moment from "moment";

// 搜索条件
const queryItems = [
  {
    label: "人员识别姓名",
    value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },

  {
    label: "人员识别",
    value: "eventworker_type",
    type: "select",
    selectOption: "SiteEventworkerType",
    defaultValue: "1",
  },
  {
    label: "岗位",
    value: "eventworker_post",
    type: "select",
    selectOption: "WorkPost",
  },
  // 新增
  // {
  //   label: "工地所属项目经理",
  //   value: "ws_jl_name",
  //   type: "input",
  //   defaultValue: "",
  // },
  // {
  //   label: "工地所属工程管家",
  //   value: "ws_gj_name",
  //   type: "input",
  //   defaultValue: "",
  // },

  // {
  //   label: "报警事件日期查询",
  //   value: "e_time",
  //   type: "dateRange",
  //   range: ["e_time_start", "e_time_end"],
  //   keyType: "WorkerAttend",
  //   defaultValue: [],
  // },
];
const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "eventworker_type",
  "eventworker_post",
  "ws_gj_name",
  "ws_jl_name",
  "e_time_start",
  "e_time_end",
  "w_event_name",
  "pageNo",
  "pageSize",
];

const columns = [
  {
    title: "姓名",
    width: "80px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },

  {
    title: "照片",
    width: "100px",
    dataIndex: "e_events",
    scopedSlots: { customRender: "e_events" },
    align: "center",
  },
  {
    title: "出生年月",
    width: "100px",
    dataIndex: "e_img",
    scopedSlots: { customRender: "e_img" },
  },
  {
    title: "工种",
    width: "80px",
    dataIndex: "e_time",
    scopedSlots: { customRender: "e_time" },
    align: "center",
  },
  {
    title: "工作起始年月",
    width: "100px",
    dataIndex: "si_eventworker",
    align: "center",
  },
  {
    title: "工种工龄",
    width: "80px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "si_eventworker_w_post" },
    align: "center",
  },

  {
    title: "籍贯",
    // width: "140px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "常住地",
    width: "100px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "等级",
    width: "80px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "勋章",
    width: "80px",
    // dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },

  // {
  //   title: "操作",
  //   width: "100px",
  //   scopedSlots: { customRender: "action" },
  //   dataIndex: "action",
  //   align: "center",
  //   fixed: "right",
  //   ellipsis: true,
  // },
];

export default {
  name: "WorkerInfo",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },

  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      visible: false,
      confirmLoading: false,
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 查看图片
      visibleImg: false,
      Image: "",
      ImgName: "",
      Imgdate: "",

      loading: false,
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  // 纠正弹窗关闭，刷新表格
  watch: {
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  mounted() {
    // this.loadData();
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("WorkerInfoColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetAlarmEvent(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        // 处理数据
        const url = this.pathUrl;
        let data = res.data.data;
        // console.log(data);
        data.forEach((el, i) => {
          let img = el.e_img;
          el.e_img = url + img;
        });
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
    exportOk() {
      this.confirmLoading = true;
      const params = {
        bc_name: this.queryParam.bc_name,
        ws_name: this.queryParam.ws_name,
        e_events: this.queryParam.e_events,
        e_time_start: this.queryParam.e_time_start,
        e_time_end: this.queryParam.e_time_end,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        eventworker_type: this.queryParam.eventworker_type,
        eventworker_post: this.queryParam.eventworker_post,
        ws_gj_name: this.queryParam.ws_gj_name,
        ws_jl_name: this.queryParam.ws_jl_name,
      };
      ExportEvent(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "报警事件列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visible = false;
        this.confirmLoading = false;
      });
    },
    exportCancel() {
      this.visible = false;
    },
    showInfo(url, record) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = record.ws_name;
      this.Imgdate = this.$Format(record.e_time);
      let that = this;
      that.Image = url;
    },
    imgCancel() {
      this.visibleImg = false;
    },

    changeTable(pagination, filters, sorter, { currentDataSource }) {
      console.log(pagination, filters, sorter, { currentDataSource });
    },

    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_name: text,
        },
      });
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
imgname :hover {
  transform: translateY(-30px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.7);
}

// 鼠标悬停蒙层
.icon_out {
  // width: 209px;
  // width: 221px;
  width: 100%;
  height: 85px;
  position: relative;
  display: flex;
}
.icon {
  font-size: 25px;
  left: 43%;
  top: 40%;
  position: absolute;
  margin: auto;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;
    // width: 209px;
    // height: 117px;
    // width: 221px;
    width: 100%;
    height: 85px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
// /deep/.ant-table table {
//   text-align: center;
// }
.my_img_viewer_body {
  overflow: hidden;
}
.OpenDrawerPreview {
  margin-right: 10px;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
